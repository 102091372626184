import React from "react"
import { css } from "@emotion/react"

type Props = {
  text: string
  handleKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const Input = ({ text, handleKeyUp }: Props) => {
  return (
    <input css={a_input} type="text" onKeyUp={handleKeyUp} placeholder={text} />
  )
}

export default Input

const a_input = css`
  border: none;
  outline: none;
  width: 95%;
  height: 85%;
  font-size: 20px;
`
