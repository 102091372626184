import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import { css } from "@emotion/react"
import Fuse from "fuse.js"
import { FaSearch } from "react-icons/fa"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../templates/layout"
import Seo from "../templates/seo"
import Sidebar from "../components/organisms/sidebar"
import Card from "../components/atoms/card"
import Listcard from "../components/molecules/list_card"
import {
  l_page_container,
  l_page_content
} from "../components/atoms/__layout"
import Input from "../components/atoms/input"

interface Page {
  title: string
  slug: string
  tags: string
  date: string
}

const Search = () => {
  const data = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        title
        author {
          name
        }
        description
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "YYYYY.MM.DD")
            tags
          }
          fields {
            slug
          }
        }
      }
    }
  }
`)

  const targets = data.allMarkdownRemark.edges
    .filter((e): e is MarkdownRemarkEdge => typeof e !== `undefined`)
    .filter(e => !e.node.frontmatter?.draft)
    .map(e => ({
      title: e.node.frontmatter?.title,
      slug: e.node.fields?.slug,
      tags: e.node.frontmatter?.tags,
      date: e.node.frontmatter?.date,
    }))
    .filter((p): p is Page => typeof (p.title && p.slug) !== `undefined`)

  const fuse = new Fuse(targets, { keys: [`title`] })

  const [results, setResults] = React.useState<Page[]>([])

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    setResults(
      fuse
        .search(event.currentTarget.value)
        .map(_ => _.item)
        .slice(0, 10)
    )
  }
  const breakpoints = useBreakpoint()
  const description = data?.site?.siteMetadata?.description
  const name = data?.site?.siteMetadata?.author?.name
  return (
    <Layout>
      <Seo title="search" />
      <div css={l_page_container}>
        <section css={l_page_content}>
          <Card>
            <form css={l_page_input}>
              <div css={input_container}>
                <Input text="記事を検索する" handleKeyUp={handleKeyUp} />
                <FaSearch />
              </div>
            </form>
          </Card>
          <ul css={index_list}>
            {results.map(result => (
              <li key={result.slug}>
                <Listcard
                  title={result.title}
                  date={result.date}
                  url={result.slug}
                  tags={result.tags}
                />
              </li>
            ))}
          </ul>
        </section>
        {breakpoints.pc ? <Sidebar toc={false} author={name} description={description} /> : <div />}
      </div>


      {/* 
      <div css={l_page_container}>
        <div css={l_page_width}>
          <Card>
            <form css={l_page_input}>
              <div css={input_container}>
                <Input text="記事を検索する" handleKeyUp={handleKeyUp} />
                <FaSearch />
              </div>
            </form>
          </Card>
          <ul css={index_list}>
            {results.map(result => (
              <li key={result.slug}>
                <Listcard
                  title={result.title}
                  date={result.date}
                  url={result.slug}
                  tags={result.tags}
                />
              </li>
            ))}
          </ul>
        </div>
        {breakpoints.pc ? <Sidebar toc={false} description={description} author={name} /> : <div />}
      </div> */}
    </Layout>
  )
}

export default Search

const l_page_input = css`
  text-align: center;
  padding: 20px;
`

const index_list = css`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`

const input_container = css`
  border-bottom: 1px solid;
  display: flex;
`
